<template>
  <aside class='page-sidebar'>
    <div class='page-logo'>
      <router-link
        class='page-logo-link press-scale-down d-flex align-items-center position-relative'
        to='/'
      >
        <div class='containerImgLogo'>
	  <img aria-roledescription='logo' :src='logoSrc' />
        </div>
        <span
          class='position-absolute text-white opacity-50 small pos-top pos-right mr-2 mt-n2'
        ></span>
      </router-link>
    </div>

        <!-- BEGIN PRIMARY NAVIGATION -->
        <nav
            id="js-primary-nav"
            class="primary-nav js-list-filter"
            role="navigation"
        >
            <div class="info-card">
                <div class="info-card-text">
                    <router-link
                        class="d-flex align-items-center text-white"
                        to="/dashboard"
                    >
                        <span class="text-truncate-lg d-inline-block"> </span>
                    </router-link>
                    <div class="d-inline-block text-truncate-lg bloc my-sm-2">
                        <small></small>
                    </div>
                </div>
                <img
                    alt="cover"
                    class="cover"
                    src="/img/card-backgrounds/cover-2-lg.png"
                />
            </div>

            <ul id="js-nav-menu" class="nav-menu js-nav-built">
                <li>
                    <router-link
                        :title="$t('menu.dashboard')"
                        class="waves-effect waves-themed"
                        to="/"
                    >
                        <i class="subheader-icon fal fa-tachometer-alt"></i>
                        <span class="nav-link-text">{{
                            $t('menu.dashboard')
                        }}</span>
                    </router-link>
                </li>
                <li class="nav-item-menu" @click="openMenu">
                    <a
                        class="waves-effect waves-themed"
                        href="#"
                        title="Tickets"
                        to="/posts"
                        data-filter-tags="posts"
                    >
                        <i class="fal fa-ticket"></i>
                        <span class="nav-link-text">Tickets</span>
                        <b class="collapse-sign"
                            ><em class="fal fa-angle-down"></em></b
                    ></a>
                    <ul>
                        <li>
                            <router-link
                                :title="$t('menu.tickets.all')"
                                to="/posts"
                                class="waves-effect waves-themed"
                                data-filter-tags="posts"
                            >
                                <span class="nav-link-text">{{
                                    $t('menu.tickets.all')
                                }}</span>
                            </router-link>
                        </li>
                        <li>
                            <router-link
                                :title="$t('menu.tickets.new')"
                                to="/posts?status=new"
                                class="waves-effect waves-themed"
                                data-filter-tags="posts"
                            >
                                <span class="nav-link-text">{{
                                    $t('menu.tickets.new')
                                }}</span>
                            </router-link>
                        </li>
                        <li>
                            <router-link
                                :title="$t('menu.tickets.open')"
                                to="/posts?status=open"
                                class="waves-effect waves-themed"
                                data-filter-tags="posts"
                            >
                                <span class="nav-link-text">{{
                                    $t('menu.tickets.open')
                                }}</span>
                            </router-link>
                        </li>
                        <li>
                            <router-link
                                :title="$t('menu.tickets.my')"
                                to="/posts?responsability=operator"
                                class="waves-effect waves-themed"
                                data-filter-tags="posts"
                            >
                                <span class="nav-link-text">{{
                                    $t('menu.tickets.my')
                                }}</span>
                            </router-link>
                        </li>
                        <li>
                            <router-link
                                :title="$t('menu.tickets.create')"
                                to="/post/create/forms"
                                class="waves-effect waves-themed"
                                data-filter-tags="posts"
                            >
                                <span class="nav-link-text">{{
                                    $t('menu.tickets.create')
                                }}</span>
                            </router-link>
                        </li>
                    </ul>
                </li>
                <li class="nav-item-menu" @click="openMenu">
                    <a
                        class="waves-effect waves-themed"
                        href="#"
                        :title="$t('menu.forms.form')"
                        to="/forms"
                        data-filter-tags="forms"
                    >
                        <i class="fas fa-poll"></i>
                        <span class="nav-link-text">{{
                            $t('menu.forms.form')
                        }}</span>
                        <b class="collapse-sign"
                            ><em class="fal fa-angle-down"></em></b
                    ></a>
                    <ul>
                        <li>
                            <router-link
                                :title="$t('menu.forms.all_forms')"
                                to="/forms"
                                class="waves-effect waves-themed"
                                data-filter-tags="forms"
                            >
                                <span class="nav-link-text">{{
                                    $t('menu.forms.all_forms')
                                }}</span>
                            </router-link>
                        </li>
                        <li>
                            <router-link
                                :title="$t('menu.forms.create')"
                                to="/forms/create"
                                class="waves-effect waves-themed"
                                data-filter-tags="forms"
                            >
                                <span class="nav-link-text">{{
                                    $t('menu.forms.create')
                                }}</span>
                            </router-link>
                        </li>
                    </ul>
                </li>
<!-- LTU
                <li class="nav-item-menu" @click="openMenu">
                    <a
                        class="waves-effect waves-themed"
                        href="#"
                        :title="$t('menu.citizens.citizen')"
                    >
                        <i class="fas fa-male"></i>
                        <span class="nav-link-text">{{
                            $t('menu.citizens.citizen')
                        }}</span>
                        <b class="collapse-sign"
                            ><em class="fal fa-angle-down"></em></b
                    ></a>
                </li>
                <li class="nav-item-menu" @click="openMenu">
                    <a
                        class="waves-effect waves-themed"
                        href="#"
                        :title="$t('menu.poi.poi')"
                    >
                        <i class="fas fa-subway"></i>
                        <span class="nav-link-text">{{
                            $t('menu.poi.poi')
                        }}</span>
                        <b class="collapse-sign"
                            ><em class="fal fa-angle-down"></em></b
                    ></a>
                </li>
-->
                <li class="nav-item-menu" @click="openMenu">
                    <a
                        class="waves-effect waves-themed"
                        href="#"
                        :title="$t('menu.statistics.statistic')"
                    >
                        <i class="fas fa-chart-line"></i>
                        <span class="nav-link-text">{{
                            $t('menu.statistics.statistic')
                        }}</span>
                        <b class="collapse-sign"
                            ><em class="fal fa-angle-down"></em></b
                    ></a>
                    <ul>
                        <li>
                            <router-link
                                :title="$t('menu.statistics.volumetry')"
                                to="/statistiques"
                                class="waves-effect waves-themed"
                                data-filter-tags="statistics"
                            >
                                <span class="nav-link-text">{{
                                    $t('menu.statistics.volumetry')
                                }}</span>
                            </router-link>
                        </li>
                        <li>
                            <router-link
                                :title="$t('menu.statistics.performance')"
                                to="/performances"
                                class="waves-effect waves-themed"
                                data-filter-tags="statistics"
                            >
                                <span class="nav-link-text">{{
                                    $t('menu.statistics.performance')
                                }}</span>
                            </router-link>
                        </li>
                    </ul>
                </li>
                <li>
                    <a
                        :title="$t('menu.map')"
                        class="waves-effect waves-themed"
                        href="/map"
                    >
                        <i class="fas fa-map-marker-alt"></i>
                        <span class="nav-link-text">{{ $t('menu.map') }}</span>
                    </a>
                </li>
                <!--        <li>-->
                <!--          <a-->
                <!--            class="waves-effect waves-themed"-->
                <!--            href="#"-->
                <!--            title="$t('menu.exports.export)"-->
                <!--          >-->
                <!--            <i class="fas fa-file-export"></i>-->
                <!--            <span class="nav-link-text">{{ $t('menu.exports.export') }}</span>-->
                <!--            <b class="collapse-sign"><em class="fal fa-angle-down"></em></b-->
                <!--          ></a>-->
                <!--          <ul>-->
                <!--            <li>-->
                <!--              <router-link-->
                <!--                :title="$t('menu.exports.citizens')"-->
                <!--                to="/export/citizens"-->
                <!--                class="waves-effect waves-themed"-->
                <!--                data-filter-tags="exports"-->
                <!--              >-->
                <!--                <span class="nav-link-text">{{-->
                <!--                  $t('menu.exports.citizens')-->
                <!--                }}</span>-->
                <!--              </router-link>-->
                <!--            </li>-->
                <!--            <li>-->
                <!--              <router-link-->
                <!--                :title="$t('menu.exports.tickets')"-->
                <!--                to="/export/tickets"-->
                <!--                class="waves-effect waves-themed"-->
                <!--                data-filter-tags="exports"-->
                <!--              >-->
                <!--                <span class="nav-link-text">{{-->
                <!--                  $t('menu.exports.tickets')-->
                <!--                }}</span>-->
                <!--              </router-link>-->
                <!--            </li>-->
                <!--          </ul>-->
                <!--        </li>-->

                <li class="nav-title">{{ $t('menu.parameters.name') }}</li>

                <li class="nav-item-menu" @click="openMenu">
                    <a
                        class="waves-effect waves-themed"
                        href="#"
                        :title="$t('menu.companies.company')"
                    >
                        <i class="fas fa-building"></i>
                        <span class="nav-link-text">{{
                            $t('menu.companies.company')
                        }}</span>
                        <b class="collapse-sign"
                            ><em class="fal fa-angle-down"></em></b
                    ></a>
                    <ul>
                        <li>
                            <router-link
                                :title="$t('menu.companies.back_office')"
                                to="/company/users"
                                class="waves-effect waves-themed"
                                data-filter-tags="companies"
                            >
                                <span class="nav-link-text">{{
                                    $t('menu.companies.back_office')
                                }}</span>
                            </router-link>
                        </li>

                        <li>
                            <router-link
                                :title="$t('menu.companies.settings')"
                                to="/company/settings"
                                class="waves-effect waves-themed"
                                data-filter-tags="compagnies"
                            >
                                <span class="nav-link-text">{{
                                    $t('menu.companies.settings')
                                }}</span>
                            </router-link>
                        </li>
                        <li>
                            <router-link
                                :title="$t('menu.companies.webhooks')"
                                to="/company/webhooks"
                                class="waves-effect waves-themed"
                                data-filter-tags="compagnies"
                            >
                                <span class="nav-link-text">{{
                                    $t('menu.companies.webhooks')
                                }}</span>
                            </router-link>
                        </li>
                    </ul>
                </li>

                <li>
                    <router-link
                        :title="$t('menu.templates')"
                        class="waves-effect waves-themed"
                        to="/admin/templates"
                    >
                        <i class="subheader-icon fal fa-envelope"></i>
                        <span class="nav-link-text">{{
                            $t('menu.templates')
                        }}</span>
                    </router-link>
                </li>
                <!--        <li>-->
                <!--          <a-->
                <!--            class="waves-effect waves-themed"-->
                <!--            href="#"-->
                <!--            title="$t('menu.parameters.parameter')"-->
                <!--          >-->
                <!--            <i class="fal fa-cog"></i>-->
                <!--            <span class="nav-link-text">{{-->
                <!--              $t('menu.parameters.parameter')-->
                <!--            }}</span>-->
                <!--            <b class="collapse-sign"><em class="fal fa-angle-down"></em></b-->
                <!--          ></a>-->
                <!--          <ul>-->
                <!--            <li>-->
                <!--              <router-link-->
                <!--                :title="$t('menu.parameters.users')"-->
                <!--                to="/admin/users"-->
                <!--                class="waves-effect waves-themed"-->
                <!--                data-filter-tags="parameters"-->
                <!--              >-->
                <!--                <span class="nav-link-text">{{-->
                <!--                  $t('menu.parameters.users')-->
                <!--                }}</span>-->
                <!--              </router-link>-->
                <!--            </li>-->
                <!--            <li>-->
                <!--              <router-link-->
                <!--                :title="$t('menu.parameters.forms')"-->
                <!--                to="/admin/forms"-->
                <!--                class="waves-effect waves-themed"-->
                <!--                data-filter-tags="parameters"-->
                <!--              >-->
                <!--                <span class="nav-link-text">{{-->
                <!--                  $t('menu.parameters.forms')-->
                <!--                }}</span>-->
                <!--              </router-link>-->
                <!--            </li>-->
                <!--            <li>-->
                <!--              <router-link-->
                <!--                :title="$t('menu.parameters.category')"-->
                <!--                to="/admin/category"-->
                <!--                class="waves-effect waves-themed"-->
                <!--                data-filter-tags="parameters"-->
                <!--              >-->
                <!--                <span class="nav-link-text">{{-->
                <!--                  $t('menu.parameters.category')-->
                <!--                }}</span>-->
                <!--              </router-link>-->
                <!--            </li>-->
                <!--            <li>-->
                <!--              <router-link-->
                <!--                :title="$t('menu.parameters.answers')"-->
                <!--                to="/admin/answers"-->
                <!--                class="waves-effect waves-themed"-->
                <!--                data-filter-tags="parameters"-->
                <!--              >-->
                <!--                <span class="nav-link-text">{{-->
                <!--                  $t('menu.parameters.answers')-->
                <!--                }}</span>-->
                <!--              </router-link>-->
                <!--            </li>-->
                <!--            <li>-->
                <!--              <router-link-->
                <!--                :title="$t('menu.parameters.signature')"-->
                <!--                to="/admin/signature"-->
                <!--                class="waves-effect waves-themed"-->
                <!--                data-filter-tags="parameters"-->
                <!--              >-->
                <!--                <span class="nav-link-text">{{-->
                <!--                  $t('menu.parameters.signature')-->
                <!--                }}</span>-->
                <!--              </router-link>-->
                <!--            </li>-->
                <!--            <li>-->
                <!--              <router-link-->
                <!--                :title="$t('menu.parameters.companies')"-->
                <!--                to="/admin/companies"-->
                <!--                class="waves-effect waves-themed"-->
                <!--                data-filter-tags="parameters"-->
                <!--              >-->
                <!--                <span class="nav-link-text">{{-->
                <!--                  $t('menu.parameters.companies')-->
                <!--                }}</span>-->
                <!--              </router-link>-->
                <!--            </li>-->
                <!--          </ul>-->
                <!--        </li>-->
            </ul>
        </nav>
        <!-- END PRIMARY NAVIGATION -->

    <!-- NAV FOOTER -->
    <div class='nav-footer shadow-top'>
      <a
        class='hidden-md-down'
        data-action='toggle'
        data-class='nav-function-minify'
        href='#'
        onclick='return false;'
      >
        <i class='ni ni-chevron-right'></i>
        <i class='ni ni-chevron-right'></i>
      </a>
      <ul class='list-table m-auto nav-footer-buttons'>
        <li class="nav-item-menu" @click="openMenu">
          <router-link
            title='Documentation'
            data-original-title='Documentation'
            data-placement='top'
            data-toggle='tooltip'
            to='/documentation'
            class='waves-effect waves-themed'
            data-filter-tags='parameters'
          >
            <i class='fad fa-question-square text-white'></i>
          </router-link>
        </li>
        <li class="nav-item-menu" @click="openMenu">
          <a
            data-original-title='Incident technique'
            data-placement='top'
            data-toggle='tooltip'
            href='https://support.bouge.io'
            title='Incident technique'
          >
            <i class='fad fa-exclamation-triangle text-white'></i>
          </a>
        </li>
        <li class="nav-item-menu" @click="openMenu">
          <a
            data-original-title='Mentions légales'
            data-placement='top'
            data-toggle='tooltip'
            href='https://www.bougemaville.com/mentions-legales/'
            title='Mentions légales'
          >
            <i class='fad fa-info-circle text-white'></i>
          </a>
        </li>
      </ul>
    </div>
    <!-- END NAV FOOTER -->
  </aside>
</template>

<style scoped>
.bloc {
  word-wrap: break-word;
}

.page-sidebar,
.page-logo {
  background-color: #ffffff;
  background-image: unset;
}

@media (min-width: 768px) and (max-width: 1536px) {
  aside.page-sidebar {
    width: 19%;
  }

  .page-logo {
    padding: 0;
    width: 100%;
  }

  .page-logo img {
    width: 180px;
    height: auto;
    object-fit: cover;
    object-position: top left;
  }
}

.nav-menu li.active > a {
  color: #004085;
}
</style>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'Menu',
  props: ['site'],
  data() {
    return {
        baseUrl : this.$store.state.baseURL,
        logoSrc : '/img/logo-1.png',
        token: this.$store.state.auth.token
    }
  },
  computed: {
        ...mapState('company', {
            company: (state) => state.companySettings,
            headerMail: (state) => state.companyHeaderMail
        })
    },
  methods: {
        ...mapActions('company', [
            'getCompanySettings',
        ]),
        openMenu(e) {
            const clickedElement = e.target
            const searchParent = 'li'
            let parent = this.upTo(clickedElement, searchParent)
            const navItems = document.getElementsByClassName('nav-item-menu')
            for (let i = 0; i < navItems.length; i++) {
                if (navItems[i] != parent)
                    navItems[i].classList.remove('active')
            }
            parent.classList.toggle('active')
        },
        upTo(el, tagName) {
            tagName = tagName.toLowerCase()

            while (el && el.parentNode) {
                el = el.parentNode
                if (el.tagName && el.tagName.toLowerCase() == tagName) {
                    return el
                }
            }
            return null
        }
  },
  mounted() {
  const $ = this;
    this.getCompanySettings().then(({settings}) => {
        const currentLogo = settings.settings_logo.filter(item => item.is_logo);
        if(currentLogo.length > 0)
            $.logoSrc = `${$.baseUrl}/storage/logo/${currentLogo[0].id}?token=${$.token}`;
    })
        // match the url and add the active class
        $('#js-nav-menu li').on('click', function (e) {
            $('#js-nav-menu li.active').removeClass('active')
            var $this = $(e.currentTarget)
            $this.addClass('active')
        })

  }
}
</script>
