import axios from 'axios'
axios.defaults.headers.common['Accept'] = 'application/json'

/**
 * Forms don't need pagination, they should always be less than 10
 * @param context
 * @returns {Promise<unknown>}
 */

const formTypes = []
function prepareParams(config) {
    return new Promise((resolve, reject) => {
        var field = config.sort

        if (config.sortDirection === 'desc') {
            field = '-' + field
        }

        var params = {
            page: config.page,
            include: config.include,
            sort: field,
            per_page: config.per_page
        }

        if (config.filters) {
            for (const [key, value] of Object.entries(config.filters)) {
                params[`filter[${key}]`] = value
            }
        }

        resolve(params)
    })
}

export default {
    getUsers({ state, rootState, commit }) {
        return new Promise((resolve, reject) => {
            try {
                prepareParams(state.usersConfig).then((params) => {
                    axios
                        .get(`${rootState.baseURL}/users/find`, { params })
                        .then((response) => {
                            const users = response.data.users
                            commit('setUsers', users)
                            resolve(users)
                        })
                        .catch((error) => {
                            reject(error)
                        })
                })
            } catch (error) {
                reject(error)
            }
        })
    },
    getUsersFilters({ state, rootState, commit }) {
        return new Promise((resolve, reject) => {
            commit('setUsersFilters', { formTypes })
            resolve()
        })
    },
    createUser({ state, rootState, commit }, user) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${rootState.baseURL}/users`, { ...user })
                .then(({ data }) => {
                    commit('setNewUser', user)
                    resolve(data)
                })
                .catch((e) => {
                    const error = e.response.data.errors
                    reject(error)
                })
        })
    },
    updateUser({ state, rootState, commit }, user) {
        return new Promise((resolve, reject) => {
            axios
                .put(`${rootState.baseURL}/users/${user.id}`, { ...user })
                .then(({ data }) => {
                    resolve(user)
                })
                .catch((e) => {
                    const error = e.response.data.errors
                    reject(error)
                })
        })
    },
    editUser({ rootState, state, commit }, id) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${rootState.baseURL}/users/find`)
                .then((response) => {
                    //TODO: Remove when real api url is used
                    const user = response.data?.users?.data.find(
                        (user) => user.id == id
                    )
                    commit('setNewUser', user)
                    resolve(user)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getMyCompany({ rootState, commit }) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${rootState.baseURL}/mycompany`)
                .then(({ data }) => {
                    commit('setCompany', data)
                    resolve(data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getCompanySettings({ rootState, commit }) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${rootState.baseURL}/company/settings`)
                .then(({ data }) => {
                    commit('setCompanySettings', data.settings)
                    commit('SetCompanyHeaderMail', data.header_mail)
                    resolve(data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    sendCompanyAdminEmailAndLogo({ state, rootState }) {
        return new Promise((resolve, reject) => {
            let body = {}
            body.mail_admin = state.companySettings.mail_admin
            body.settings_logo = state.companySettings.settings_logo
            axios
                .post(`${rootState.baseURL}/company/settings`, body)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                })
        })
    },
    sendCompanySettings({ state, rootState }) {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    `${rootState.baseURL}/company/settings`,
                    state.companySettings
                )
                .then(({ data }) => {
                    resolve(data)
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                })
        })
    },
    sendCompanyHeaderMail({ state, rootState }) {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    `${rootState.baseURL}/company/settings/header`,
                    state.companyHeaderMail
                )
                .then(({ data }) => {
                    resolve(data)
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                })
        })
    },
    editWebhook({ rootState, state, commit }, id) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${rootState.baseURL}/webhooks/${id}`)
                .then(({ data }) => {
                    commit('setNewWebhook', data.webhook)
                    resolve(data.webhook)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    createWebhook({ state, rootState, commit }, data) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${rootState.baseURL}/webhooks`, state.newWebhook)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                })
        })
    },
    updateWebhook({ state, rootState, commit }, data) {
        return new Promise((resolve, reject) => {
            axios
                .put(
                    `${rootState.baseURL}/webhooks/${state.newWebhook.id}`,
                    state.newWebhook
                )
                .then(({ data }) => {
                    resolve(data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getCitizen({ rootState }, id) {
        return new Promise((resolve, reject) => {
            try {
                axios
                    .get(`${rootState.baseURL}/citizen/${id}`)
                    .then((response) => {
                        resolve(response.data.citizen)
                    })
                    .catch((error) => {
                        reject(error)
                    })
            } catch (error) {
                reject(error)
            }
        })
    }
}
